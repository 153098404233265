import './index.css'

const NotFound =()=>{
    console.log("nav")
    return(
        <div className='container margin'>
            <h1>NotFound</h1>
        </div>
    )
}
export default NotFound